import React from 'react';

import SafeLink from '@/components/SafeLink';
import { policy, footerItem } from '@/constants/';

import styles from '@/components/Footer/Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.root}>
      <div>(주) 타임퍼센트</div>
      <div className={styles.info}>
        {footerItem.map(({ title, info }) => (
          <div key={title}>
            <span>{title}</span>
            <span>&nbsp;:&nbsp;</span>
            <span>{info}</span>
          </div>
        ))}
      </div>
      <div className={styles.policyWrapper}>
        {policy.map((option) => {
          return (
            <SafeLink key={option.title} href={option.link} openInNewTab>
              <div className={styles.option}>{option.title}</div>
            </SafeLink>
          );
        })}
      </div>
    </footer>
  );
};

export default Footer;
