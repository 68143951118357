import { useCallback, useEffect, useRef } from 'react';

import { useRouter } from 'next/router';

import { UrlObject } from 'url';

export const useSafeRouter = () => {
  const onChangingRef = useRef(false);
  const router = useRouter();

  const handleRouteChange = () => {
    onChangingRef.current = false;
  };

  const safePush = useCallback(
    (path: string | UrlObject) => {
      if (onChangingRef.current) {
        return;
      }
      onChangingRef.current = true;
      router.push(path);
    },
    [router],
  );

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);
  return { router, safePush };
};
