import { useEffect } from 'react';

import { routes } from '@/constants/';

import { useSession } from 'next-auth/react';

import { useSafeRouter } from './useSafeRouter';

export const useSyncUsername = () => {
  const { safePush, router } = useSafeRouter();
  const { username } = router.query;
  const { data: session, status } = useSession();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    // url에서 username을 쓰지 않는 페이지
    if (!username) {
      return;
    }

    if (status === 'loading') {
      return;
    }

    if (status === 'unauthenticated') {
      safePush(routes.signInRoute);
      return;
    }

    if (status === 'authenticated' && username !== session.username) {
      router.replace(
        {
          pathname: router.pathname,
          query: { ...router.query, username: session.username },
        },
        undefined,
        { shallow: true },
      );
      return;
    }
  }, [router, safePush, session, status, username]);
};
