import {
  TradingViewCandleKind,
  tradingViewCandleToCandleKindMap,
} from '@/constants';
import {
  addBuyStrategyToPreviewExprs,
  addStrategyToFormulaExprs,
} from '@/features/adaptor/converters/';
import {
  BacktestingForm,
  BacktestingPreviewForm,
  PreviewKind,
} from '@/features/api/chart/backtesting/type';
import { parseNumberFormatString } from '@/features/utils';
import { BacktestingSettingState } from '@/store/backtesting';
import { ChartDataState } from '@/store/chartData';

export type BacktestingFormState = Pick<
  BacktestingSettingState,
  | 'startAndEndDate'
  | 'startAndEndTime'
  | 'exchange'
  | 'market'
  | 'principal'
  | 'buyFormulas'
  | 'sellFormulas'
  | 'conditionExpression'
  | 'hasDCA'
  | 'buyDCAConfig'
  | 'minPrincipal'
  | 'sellConfig'
  | 'name'
  | 'buyOnceInitially'
>;

export const convertStateToBacktestingForm = (
  backtestingFormState: BacktestingFormState,
): BacktestingForm => {
  const {
    startAndEndDate,
    startAndEndTime,
    exchange,
    market,
    principal,
    buyFormulas,
    sellFormulas,
    conditionExpression,
    hasDCA,
    buyDCAConfig,
    minPrincipal,
    sellConfig,
    name,
    buyOnceInitially,
  } = backtestingFormState;

  const { buyStrategy, sellStrategy } = conditionExpression;
  const [startDate, endDate] = startAndEndDate;
  const [startTime, endTime] = startAndEndTime;
  const start = `${startDate}T${startTime}:00`;
  const end = `${endDate}T${endTime}:00`;

  const strategy = {
    name,
    buy_exprs: addStrategyToFormulaExprs(buyFormulas, buyStrategy),
    sell_exprs: addStrategyToFormulaExprs(sellFormulas, sellStrategy),
    profit_cut: sellConfig.profit_cut,
    loss_cut: sellConfig.loss_cut,
    market_max_holding_minutes: sellConfig.market_max_holding_minutes || 0,
    ...(hasDCA && buyDCAConfig.scale_in_max_steps
      ? { scale_in_max_steps: buyDCAConfig.scale_in_max_steps }
      : {}),
    ...(hasDCA && buyDCAConfig.scale_in_price_step
      ? { scale_in_price_step: buyDCAConfig.scale_in_price_step }
      : {}),
    ...(hasDCA && buyDCAConfig.scale_in_relative_ratio
      ? {
          scale_in_relative_ratio: buyDCAConfig.scale_in_relative_ratio,
        }
      : {}),
    ...(hasDCA && buyDCAConfig.scale_in_cumulative_ratio
      ? {
          scale_in_cumulative_ratio: buyDCAConfig.scale_in_cumulative_ratio,
        }
      : {}),
    ...(hasDCA && minPrincipal ? { min_principal: minPrincipal } : {}),
    ...(buyOnceInitially ? { buy_once_initially: true } : {}),
  };

  return {
    name,
    start,
    end,
    crypto_exchange: exchange,
    principal: parseNumberFormatString(principal) * 10_000,
    markets: [market],
    strategy,
  };
};

interface PreviewFormProps {
  kind: PreviewKind;
  chartDataState: Pick<ChartDataState, 'symbol' | 'unit' | 'visibleRange'>;
  backtestingSettingState: Pick<
    BacktestingSettingState,
    | 'name'
    | 'buyFormulas'
    | 'sellFormulas'
    | 'conditionExpression'
    | 'sellConfig'
    | 'hasDCA'
    | 'buyDCAConfig'
  >;
}

const convertChartSymbolToPreviewMarket = (symbol: string): string => {
  const market = symbol.split(':')[1];
  const [code, currency] = market.split('/');
  return `${currency}-${code}`;
};

export const convertStateToPreviewForm = ({
  kind,
  chartDataState,
  backtestingSettingState,
}: PreviewFormProps): BacktestingPreviewForm => {
  const {
    name,
    buyFormulas,
    sellFormulas,
    conditionExpression,
    sellConfig,
    hasDCA,
    buyDCAConfig,
  } = backtestingSettingState;
  const market = convertChartSymbolToPreviewMarket(chartDataState.symbol);
  const currentTimestamp = Date.now();
  let endTimestamp = chartDataState.visibleRange
    ? chartDataState.visibleRange!.to * 1000
    : 0;
  if (currentTimestamp < endTimestamp) {
    endTimestamp = currentTimestamp;
  }
  const startDate = chartDataState.visibleRange
    ? new Date(chartDataState.visibleRange!.from * 1000)
        .toISOString()
        .replace('Z', '')
    : new Date().toISOString().replace('Z', '');
  const endDate = new Date(endTimestamp).toISOString().replace('Z', '');
  const chartCandleKind =
    tradingViewCandleToCandleKindMap[
      chartDataState.unit as TradingViewCandleKind
    ];

  const strategy = {
    name,
    buy_exprs: addBuyStrategyToPreviewExprs(
      buyFormulas,
      conditionExpression.buyStrategy,
      chartCandleKind,
    ),
    sell_exprs: addBuyStrategyToPreviewExprs(
      sellFormulas,
      conditionExpression.sellStrategy,
      chartCandleKind,
    ),
    profit_cut: sellConfig.profit_cut,
    loss_cut: sellConfig.loss_cut,
    market_max_holding_minutes: sellConfig.market_max_holding_minutes,
    ...(hasDCA && buyDCAConfig.scale_in_max_steps
      ? { scale_in_max_steps: buyDCAConfig.scale_in_max_steps }
      : {}),
    ...(hasDCA && buyDCAConfig.scale_in_price_step
      ? { scale_in_price_step: buyDCAConfig.scale_in_price_step }
      : {}),
    ...(hasDCA && buyDCAConfig.scale_in_relative_ratio
      ? {
          scale_in_relative_ratio: buyDCAConfig.scale_in_relative_ratio,
        }
      : {}),
    ...(hasDCA && buyDCAConfig.scale_in_cumulative_ratio
      ? {
          scale_in_cumulative_ratio: buyDCAConfig.scale_in_cumulative_ratio,
        }
      : {}),
  };

  return {
    name,
    strategy,
    config: {
      start: startDate,
      end: endDate,
      crypto_exchange: 'coinone',
      market,
    },
    preview: kind,
  };
};
