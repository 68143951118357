export const tabKeys = {
  TRANSACTIONS: 'transactions',
  HISTORY: 'history',
  BACKTESTING_RESULT: 'backtesting_result',
  TRADING_RESULT: 'trading_result',
  PAPER_RESULT: 'paper_result',
  STRATEGY_INFO: 'strategy_info',
  ASSET: 'asset',
  TRANSACTION: 'transaction',
};
