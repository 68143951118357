// 특정 컴포넌트 등에서 사용하기 위한 특수 목적 함수들

import { MIN_PRINCIPAL_BY_TRANSACTION } from '@/constants';

import { BacktestingHistory } from '@/features/api/chart/backtesting/type';

import {
  convertMinutesToDays,
  convertMinutesToDHM,
  convertUTCToKST,
  decimalFormatter,
  roundFloat,
} from '@/features/utils';

interface SplitRatioResult {
  ratio: number;
  amount: number;
}

// MEMO: TB-5621
/**
 * DCA에서 각 단계의 비율과 금액을 계산하는 함수
 * @param ratioStandard 비율 계산 기준 (relative: 이전 회차보다, cumulative: 누적 회차보다)
 * @param r 매수 비중
 * @param i 현재 회차
 * @param n 총 회차 수
 * @param principal 총 투자금액
 * @returns { SplitRatioResult } 비율과 금액
 */
export const calculateSplitRatio = ({
  ratioStandard,
  r,
  i,
  n,
  principal,
}: {
  ratioStandard: 'relative' | 'cumulative';
  r: number;
  i: number;
  n: number;
  principal: number;
}): SplitRatioResult => {
  if (ratioStandard === 'relative') {
    const ratio = Math.pow(r, i - 1);
    const totalRatio =
      Number(r) === 1
        ? n // r=1일 때는 모든 항이 1이므로 합은 n
        : (1 - Math.pow(r, n)) / (1 - r);
    const normalizedRatio = (ratio / totalRatio) * 100;
    const amount = (ratio / totalRatio) * principal;

    return {
      ratio: Number(normalizedRatio.toFixed(2)),
      amount: Math.floor(amount),
    };
  }

  // cumulative case
  if (i === 1) {
    const ratio = 1;
    const steps = Array.from({ length: n - 1 }).reduce<number[]>((acc) => {
      const prevSum = acc.reduce((sum, val) => sum + val, 1);
      return [...acc, prevSum * r];
    }, []);

    const totalRatio = 1 + steps.reduce((sum, val) => sum + val, 0);
    const normalizedRatio = (ratio / totalRatio) * 100;
    const amount = (ratio / totalRatio) * principal;

    return {
      ratio: Number(normalizedRatio.toFixed(2)),
      amount: Math.floor(amount),
    };
  }

  const steps = Array.from({ length: i - 1 }).reduce<number[]>((acc) => {
    const prevSum = acc.reduce((sum, val) => sum + val, 1);
    return [...acc, prevSum * r];
  }, []);

  const ratio = steps[steps.length - 1];
  const allSteps = Array.from({ length: n - 1 }).reduce<number[]>((acc) => {
    const prevSum = acc.reduce((sum, val) => sum + val, 1);
    return [...acc, prevSum * r];
  }, []);

  const totalRatio = 1 + allSteps.reduce((sum, val) => sum + val, 0);
  const normalizedRatio = (ratio / totalRatio) * 100;
  const amount = (ratio / totalRatio) * principal;

  return {
    ratio: Number(normalizedRatio.toFixed(2)),
    amount: Math.floor(amount),
  };
};

/**
 * DCA에서 각 단계의 비율과 금액을 기획된 형식대로 포맷팅하는 함수
 * @param ratio
 * @param amount
 * @returns
 */
export const formatSplitRatio = ({ ratio, amount }: SplitRatioResult) =>
  `${ratio.toFixed(2)}% / ${amount.toLocaleString()}원`;

/**
 * DCA에서 최소 투자금액을 계산하는 함수
 * @param ratioStandard 비율 계산 기준 (relative: 이전 회차보다, cumulative: 누적 회차보다)
 * @param buyRatioValue 매수 비중
 * @param scaleInMaxSteps 회차
 * @returns { number } 최소 투자금액
 */
export const calculateMinRequiredPrincipal = ({
  ratioStandard,
  buyRatioValue,
  scaleInMaxSteps,
}: {
  ratioStandard: 'relative' | 'cumulative';
  buyRatioValue: number;
  scaleInMaxSteps: number;
}) => {
  if (
    !buyRatioValue ||
    !scaleInMaxSteps ||
    buyRatioValue <= 0 ||
    scaleInMaxSteps <= 0
  ) {
    return MIN_PRINCIPAL_BY_TRANSACTION;
  }

  if (ratioStandard === 'relative') {
    const ratios = Array.from({ length: scaleInMaxSteps }, (_, i) =>
      Math.pow(buyRatioValue, i),
    );

    const totalRatio = ratios.reduce((sum, ratio) => sum + ratio, 0);
    const minRatio = Math.min(...ratios);
    const minPrincipal = (MIN_PRINCIPAL_BY_TRANSACTION * totalRatio) / minRatio;

    return (
      Math.ceil(minPrincipal / MIN_PRINCIPAL_BY_TRANSACTION) *
      MIN_PRINCIPAL_BY_TRANSACTION
    );
  }

  if (ratioStandard === 'cumulative') {
    const { ratios, totalRatio } = Array.from({
      length: scaleInMaxSteps,
    }).reduce<{ ratios: number[]; totalRatio: number }>(
      (acc, _, idx) => {
        const ratio = idx === 0 ? 1 : acc.totalRatio * buyRatioValue;
        return {
          ratios: [...acc.ratios, ratio],
          totalRatio: acc.totalRatio + ratio,
        };
      },
      { ratios: [], totalRatio: 0 },
    );

    const minRatio = Math.min(...ratios);
    if (minRatio <= 0) {
      return MIN_PRINCIPAL_BY_TRANSACTION;
    }

    const minPrincipal = (MIN_PRINCIPAL_BY_TRANSACTION * totalRatio) / minRatio;
    return (
      Math.ceil(minPrincipal / MIN_PRINCIPAL_BY_TRANSACTION) *
      MIN_PRINCIPAL_BY_TRANSACTION
    );
  }

  return MIN_PRINCIPAL_BY_TRANSACTION;
};

export interface ConvertedBacktestingHistoryForMobileTable {
  market: string;
  ror: number | undefined;
  fee: number;
  kind: '거래완료' | '거래중';
  holdingPeriodDays: string;
  holdingPeriodDHS: string;
  pnl: number | undefined;
  buyPrice: number;
  sellPrice: number;
  buyCount: number;
  sellCount: number;
  slippage: number;
  buyDatetime: {
    date: string;
    time: string;
  };
  sellDatetime: {
    date: string;
    time: string;
  };
}

/**
 * 백테스팅 거래내역을 모바일 테이블에 맞게 변환하는 함수
 * @param data 백테스팅 거래내역 from server
 */
export const convertBacktestingHistoryForMobileTable = (
  data: BacktestingHistory,
): ConvertedBacktestingHistoryForMobileTable[] => {
  if (!data || !data.records) {
    return [];
  }

  return data.records.map((record) => {
    const hasSelled = record.records.length >= 2;

    return {
      market: record.market,
      kind: hasSelled ? '거래완료' : '거래중',
      holdingPeriodDays: `${decimalFormatter(
        convertMinutesToDays(record.holding_period_in_minutes || 0),
      )}일`,
      holdingPeriodDHS: convertMinutesToDHM(record.holding_period_in_minutes),
      ror: record.trade_ror,
      pnl: record.trade_real_pnl,
      buyPrice: roundFloat(record.average_buy_price),
      sellPrice: roundFloat(record.average_sell_price),
      buyCount: roundFloat(record.buy_count, 4),
      sellCount: roundFloat(record.sell_count, 4),
      fee: roundFloat(record.fee, 3),
      slippage: record.slippage,
      buyDatetime: convertUTCToKST(
        new Date(
          record.records.find((record) => record.kind === 1)?.ordered_at || '',
        ),
      ),
      sellDatetime: convertUTCToKST(
        new Date(
          record.records.find((record) => record.kind === 2)?.ordered_at || '',
        ),
      ),
    };
  });
};
