import { TradingLog } from '@/features/api/chart/trading/type';
import {
  parseISO,
  parse,
  set,
  addHours,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

/**
 * UTC 시간 문자열을 한국 표준시(KST)로 변환
 * @param {string} datetime - 변환할 UTC 시간 문자열
 * @returns {string} 변환된 한국 표준시(KST)를 ISO 형식의 문자열로 반환
 * @example
 * convertUTCDateStringToKST('2021-08-01T00:00:00+09:00'); // "2021-08-01T00:00:00.000Z"
 */
export const convertUTCDateStringToKST = (datetime: string): string => {
  const date = parseISO(datetime);
  const kstDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);
  return kstDate.toISOString();
};

/**
 * ISO 형식의 날짜와 시간 문자열을 날짜와 시간 부분으로 분리
 * @param {string | undefined} datetime - 분리할 ISO 형식의 날짜와 시간 문자열
 * @param {boolean} [includeSeconds=false] - 초를 포함할지 여부를 나타내는 값, 기본값은 false
 * @param {boolean} [removeMilliseconds=false] - 밀리초를 제거할지 여부를 나타내는 값 기본값은 false
 * @returns {{ date: string, time: string }} 날짜와 시간으로 분리된 객체를 반환
 * @example
 * splitDateTimeOfISOString('2021-08-01T12:34:56+09:00'); // { date: '2021-08-01', time: '12:34' }
 */
export const splitDateTimeOfISOString = (
  datetime: string | undefined,
  includeSeconds = false,
  removeMilliseconds = false,
) => {
  if (!datetime) {
    return { date: '', time: '' };
  }

  const datetimeSplit = datetime.split('T');
  const date = datetimeSplit[0];
  const timeSplit = datetimeSplit[1].split(':');
  const time = includeSeconds
    ? datetimeSplit[1].split('+')[0]
    : `${timeSplit[0]}:${timeSplit[1]}`;
  if (removeMilliseconds) {
    return { date, time: time.split('.')[0] };
  }
  return { date, time };
};

/**
 * 두 날짜 사이의 일수 차이를 계산
 * @param {string} startDate - 시작 날짜
 * @param {string} endDate - 종료 날짜
 * @returns {number} 시작 날짜와 종료 날짜 사이의 일수 차이를 반환
 * @example
 * getDateDifference('2021-08-01', '2021-08-10'); // 9
 */
export const getDateDifference = (
  startDate: string,
  endDate: string,
): number => {
  const diffInMilliseconds =
    new Date(endDate).getTime() - new Date(startDate).getTime();
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  return Math.floor(diffInMilliseconds / millisecondsInADay);
};

/**
 * 한국 시간(KST)을 기준으로 한 UTC 밀리초로 변환
 * @param {string} koreanDate - 한국 표준시(KST)로 표현된 날짜 문자열
 * @returns {number} 변환된 UTC 밀리초를 반환
 * @example
 * convertKoreanDateToUtcMilliseconds('2021-08-01'); // 1627711200000
 */
export const convertKoreanDateToUtcMilliseconds = (
  koreanDate: string,
): number => {
  const koreanTime = set(parse(koreanDate, 'yyyy-MM-dd', new Date()), {
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const utcTime = addHours(koreanTime, -9);
  const utcMilliseconds = utcTime.getTime();

  return utcMilliseconds;
};

/**
 * 시간 간격을 계산
 * @param {number} hourGap - 시간 간격의 시간 차
 * @param {number} minuteGap - 시간 간격의 분 차
 * @returns {[number, number]} 시간 간격의 절대 시간(시간과 분)을 배열로 반환
 * @example
 * countTimeGap(3, 45); // [20, 15]
 */
export const countTimeGap = (
  hourGap: number,
  minuteGap: number,
): [number, number] => {
  if (hourGap > 0) {
    if (minuteGap > 0) {
      return [Math.abs(24 - hourGap - 1), Math.abs(60 - minuteGap)];
    } else {
      return [Math.abs(24 - hourGap), Math.abs(minuteGap)];
    }
  } else {
    if (minuteGap > 0) {
      return [Math.abs(hourGap + 1), Math.abs(60 - minuteGap)];
    } else {
      return [Math.abs(hourGap), Math.abs(minuteGap)];
    }
  }
};

/**
 * 특정 날짜와 시간에 대한 Unix 타임스탬프를 반환
 * @param {string} day - 날짜('YYYY-MM-DD')
 * @param {string} time - 시간('HH:MM')
 * @param {boolean} [inMilliseconds=false] - 결과를 밀리초로 반환할지 여부, 기본값은 false
 * @returns {number} Unix 타임스탬프를 반환, 기본적으로 초 단위이지만 inMilliseconds가 true일 경우 밀리초로 반환
 * @example
 * getUnixTimestampFromDayAndTime('2024-03-20', '14:30'); // 1710912600
 */
export const getUnixTimestampFromDayAndTime = (
  day: string,
  time: string,
  inMilliseconds = false,
): number => {
  const dayArr = day.split('-');
  const timeArr = time.split(':');
  const date = new Date(
    Number(dayArr[0]),
    Number(dayArr[1]) - 1,
    Number(dayArr[2]),
    Number(timeArr[0]),
    Number(timeArr[1]),
    0,
  );
  if (inMilliseconds) {
    return date.getTime();
  }
  return Math.floor(date.getTime() / 1000);
};

export const convertUTCToKST = (datetime: Date) => {
  try {
    const [date, time] = formatInTimeZone(
      datetime,
      'Asia/Seoul',
      'yyyy-MM-dd HH:mm',
    ).split(' ');
    return { date, time };
  } catch {
    return { date: '', time: '' };
  }
};

/**
 * ISO 8601 형식의 문자열을 Date 객체로 파싱
 * @param {string} s - ISO 8601 형식의 문자열
 * @returns {Date} 파싱된 Date 객체를 반환
 * @example
 * parseISOString('2024-03-20T05:00:00.000Z'); // Date 객체, Wed Mar 20 2024 14:00:00 GMT+0900 (한국 표준시)
 */
export const parseISOString = (s: string): Date => {
  const b = s.split(/\D+/);
  return new Date(
    Date.UTC(
      parseInt(b[0]),
      parseInt(b[1]) - 1,
      parseInt(b[2]),
      parseInt(b[3]),
      parseInt(b[4]),
      parseInt(b[5]),
      b[6] !== '' ? parseInt(b[6]) : 0,
    ),
  );
};

/**
 * 총 분을 일(days)로 변환
 * @param {number} minutes - 변환할 총 분
 * @returns {number} 변환된 일(days)을 반환
 * @example
 * convertMinutesToDays(1500); // 1.04
 */
export const convertMinutesToDays = (minutes: number | string): number => {
  const days = Number(minutes) / (24 * 60);

  return Math.round(days * 100) / 100;
};

/**
 * 총 분을 시간(hours)으로 변환
 * @param {number} minutes - 변환할 총 분
 * @returns {number} 변환된 시간(hours)을 반환
 */
export const convertMinutesToHours = (minutes: number | string): number => {
  const hours = Number(minutes) / 60;

  return Math.round(hours * 100) / 100;
};

/**
 * 일(day)을 분으로 변환
 * @param {number} days - 변환할 일(day)
 * @returns {number} 변환된 분을 반환
 * @example
 * convertDaysToMinutes(1.04); // 1497
 */
export const convertDaysToMinutes = (days: number | string): number => {
  const minutes = Number(days) * 24 * 60;

  return Math.round(minutes);
};

/**
 * 시간(hours)을 분으로 변환
 * @param {number} hours - 변환할 시간(hours)
 * @returns {number} 변환된 분을 반환
 */
export const convertHoursToMinutes = (hours: number | string): number => {
  const minutes = Number(hours) * 60;

  return Math.round(minutes);
};

/**
 * 총 분을 일(day), 시(hour), 분(minute)으로 변환
 * @param {number} totalMinutes - 변환할 총 분
 * @returns {{ days: number, hours: number, minutes: number }} 일, 시, 분으로 변환된 객체를 반환
 * @example
 * convertMinutesToDHM(1500); // { days: 1, hours: 1, minutes: 0 }
 */
export const convertMinutesToDHM = (minutes: number | string): string => {
  const minutesPerDay = 1440;
  const minutesPerHour = 60;

  const d = Math.floor(Number(minutes) / minutesPerDay);
  const h = Math.floor((Number(minutes) % minutesPerDay) / minutesPerHour);
  const m = Number(minutes) % minutesPerHour;

  return `${d}일 ${h}시간 ${m}분`;
};

/**
 * 주어진 시간 배열의 합계를 계산
 * @param {TradingLog[]} timeArray - 시간 로그 배열
 * @returns {string} 시간의 합계를 형식화하여 반환
 * @example
 * calculateSumOfTimes([{ started_at: '2024-03-20T08:00:00', stopped_at: '2024-03-20T12:30:00' }]); // '4시간 30분 0초'
 */
export const calculateSumOfTimes = (timeArray: TradingLog[]): string => {
  if (timeArray.length === 0) {
    return '-';
  }

  const now = new Date();
  const totalTime = timeArray.reduce((total, entry) => {
    const startedAt = entry.started_at ? new Date(entry.started_at) : now;
    const stoppedAt = entry.stopped_at ? new Date(entry.stopped_at) : now;

    const timeDifference = stoppedAt.getTime() - startedAt.getTime();
    return total + timeDifference;
  }, 0);

  const days = Math.floor(totalTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor((totalTime / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((totalTime / (1000 * 60)) % 60);
  const seconds = Math.floor((totalTime / 1000) % 60);

  if (totalTime <= 0) {
    return '-';
  }

  if (days > 0) {
    return `${days}일 ${hours.toString()}시간 ${minutes.toString()}분 ${seconds.toString()}초`;
  }

  if (hours > 0) {
    return `${hours}시간 ${minutes.toString()}분 ${seconds.toString()}초`;
  }

  return `${minutes}분 ${seconds.toString()}초`;
};

/**
 * 주어진 두 날짜 사이의 시간 차이를 형식화하여 반환
 * @param {Date} start - 시작 날짜
 * @param {Date} [end=new Date()] - 종료 날짜, 기본값은 현재 시간
 * @param {string} [postfix='전'] - 시간 차이 뒤에 추가할 후행 문자열
 * @param {boolean} [hasAllUnits=false] - 모든 시간 단위를 포함할지 여부
 * @returns {string} 시간 차이를 형식화한 문자열을 반환
 * @example
 * calculateTimeDifferece(new Date('2024-03-19T08:00:00'), new Date('2024-03-20T12:30:00')); //  '1일 전'
 */
export const calculateTimeDifferece = ({
  start,
  end = new Date(),
  postfix = '',
  hasAllUnits = false,
}: {
  start: Date;
  end?: Date;
  postfix?: string;
  hasAllUnits?: boolean;
}): string => {
  if (!hasAllUnits) {
    const days = differenceInDays(end, start);
    const hours = differenceInHours(end, start);
    const minutes = differenceInMinutes(end, start);
    const seconds = differenceInSeconds(end, start);

    if (days > 0) {
      return `${days}일 ${postfix}`;
    }

    if (hours > 0) {
      return `${hours}시간 ${postfix}`;
    }

    if (minutes > 0) {
      return `${minutes}분 ${postfix}`;
    }

    return `${seconds}초 ${postfix}`;
  }

  const totalSeconds = differenceInSeconds(end, start);
  const days = Math.floor(totalSeconds / (60 * 60 * 24));
  const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
  const seconds = totalSeconds % 60;
  let result = '';

  if (days > 0) {
    result += `${days}일 `;
  }
  if (hours > 0) {
    result += `${hours}시간 `;
  }
  if (minutes > 0) {
    result += `${minutes}분 `;
  }
  if (seconds > 0 || result === '') {
    result += `${seconds}초`;
  }

  return result.trim();
};
