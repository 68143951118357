export type LogicalOperator = 'and' | 'or';
export const logicalOperators: LogicalOperator[] = ['and', 'or'];

export enum OperatorKinds {
  And = 'and',
  Or = 'or',
}

export type OperatorTypes = {
  [K in keyof typeof OperatorKinds]: (typeof OperatorKinds)[K];
}[keyof typeof OperatorKinds];

export const MIN_PRINCIPAL_BY_TRANSACTION = 10000;
export const DCA_MIN_MAX_STEPS = 1;
export const DCA_MAX_MAX_STEPS = 100;
export const DCA_MIN_PRICE_STEP = -99.99;
export const DCA_MAX_PRICE_STEP = 99.99;
export const DCA_BUY_RATIO_VALUE_MIN = 0.01;
export const DCA_BUY_RATIO_VALUE_MAX = 100;
