export const GUIDE_URL = 'https://timepercentcorp.com/guide/guide_robot';

export const rootRoute = '/';

export const ONBOARDING_EXCHANGE_STEP1_ROUTE = '/onboarding/exchange/step1'; // 거래소 연결
export const ONBOARDING_EXCHANGE_STEP2_ROUTE = '/onboarding/exchange/step2'; // 코인원 계정
export const ONBOARDING_EXCHANGE_STEP3_ROUTE = '/onboarding/exchange/step3'; // 코인원 회원가입 전
export const ONBOARDING_EXCHANGE_STEP4_ROUTE = '/onboarding/exchange/step4'; // 코인원 로그인 전
export const ONBOARDING_EXCHANGE_STEP5_ROUTE = '/onboarding/exchange/step5'; // 거래소 연결 완료

export const ONBOARDING_FIRST_VISIT_ROUTE = '/onboarding/firstvisit'; // 첫 방문

export const companyRoute = 'http://timepercentcorp.com/';
export const termsRoute = '/policy/terms';
export const privacyRoute = '/policy/privacy';
export const homeRoute = '/home';
export const signInRoute = '/signin';
export const signUpRoute = '/signup';
export const blogRoute = '/blog';

export const backtestingRoute = '/backtesting';
export const backtestLoadingRoute = (taskId: string) =>
  `/backtesting/loading/${taskId}`;
export const backtestingNoResultRoute = (id: string) =>
  `/backtesting/none/${id}`;
export const strategyRoute = '/strategy';
export const marketplaceRoute = '/marketplace';
export const marketplaceRegisterStrategyRoute = '/marketplace/new';

export const marketplaceStrategyRoute = (backtestingId: string) =>
  `/marketplace/${backtestingId}`;

export const myAssetRoute = (username: string) => `/user/${username}/asset`;

export const myStrategyRoute = (username: string) =>
  `/user/${username}/strategy`;

export const myStrategyDetailRoute = ({
  username,
  strategyId,
}: {
  username: string | null;
  strategyId: string;
}) => {
  return `/user/${username}/strategy/${strategyId}`;
};

export const myPageRoute = (username: string) => `/user/${username}`;

export const tradingrobotRoute = (username: string) =>
  `/user/${username}/tradingrobot`;

export const addTradingrobotRoute = (username: string) =>
  `/user/${username}/tradingrobot/new`;

export const tradingrobotDetailRoute = ({
  username,
  robotId,
}: {
  username: string;
  robotId: string;
}) => `/user/${username}/tradingrobot/${robotId}`;

export const myAssetPageRoute = (username: string) => `/user/${username}/asset`;
