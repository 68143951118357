import { env } from '@/features/env';
import * as Sentry from '@sentry/nextjs';
import axios from 'axios';
import { IncomingMessage } from 'http';

import { getSession } from 'next-auth/react';

/**
 * 코인원 세션 getter.
 * @param {IncomingMessage} req - 요청 객체
 * @returns {Promise<any>} 세션 정보를 포함하는 프로미스 객체를 반환
 */

const BASE_URL = `${env.CHART_SERVICE_HOSTNAME}/api/v1/chart`;

const chartAPI = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const getCoinoneSession = async (req: IncomingMessage): Promise<any> => {
  const session = await getSession({ req });

  if (session) {
    const { accessToken, tokenType } = session;
    try {
      const result = await chartAPI.get(`${BASE_URL}/trading/auth/coinone`, {
        headers: {
          Authorization: `${tokenType} ${accessToken}`,
        },
        validateStatus: (status: number) => {
          Sentry.captureMessage(`getCoinoneSession with status ${status}`);
          return status >= 200 && status < 300;
        },
      });

      return result.data;
    } catch (error: any) {
      Sentry.captureException(error, {
        extra: { error, request: error.request, response: error.response },
      });

      return null;
    }
  }
};
