import { parseAsString, useQueryState } from 'nuqs';

interface TabState {
  key?: string;
  defaultTab?: string;
}

export const useTabState = ({
  key = 'tab',
  defaultTab = '',
}: TabState = {}) => {
  const [currentTab, setCurrentTab] = useQueryState(
    key,
    parseAsString.withDefault(defaultTab),
  );

  return {
    currentTab,
    setCurrentTab,
  };
};
