import {
  logicalOperators,
  LogicalOperator,
  CandleKind,
  Formula as FormulaClient,
  MarketIndicatorInfo,
} from '@/constants/';

import {
  StrategyExprs,
  Formula as FormulaServer,
} from '@/features/api/chart/strategy/type';

export const addBuyStrategyToPreviewExprs = (
  formulas: FormulaClient[],
  buyStrategy: Array<string | LogicalOperator>,
  previewCandle: CandleKind,
): StrategyExprs => {
  return buyStrategy.map((element) => {
    if (logicalOperators.some((operator) => operator === element)) {
      return element as LogicalOperator;
    }

    // formulas 배열 내의 요소의 candleKind 키를 kind로 변경
    const formula = formulas.find((formula) => formula.name === element);

    if (formula) {
      const { name, tokens } = formula;

      const convertedTokens = tokens.map((token) => {
        if (typeof token === 'object') {
          const { indicator, shift, ...rest } = token as MarketIndicatorInfo;
          return {
            ...rest,
            name: indicator.name,
            shift: shift - 1,
          };
        } else {
          return token;
        }
      });
      return {
        name,
        tokens: convertedTokens,
        kind: previewCandle,
      } as FormulaServer;
    }
  }) as StrategyExprs;
};
