import {
  LogicalOperator,
  logicalOperators,
  Formula as FormulaClient,
  MarketIndicatorInfo,
} from '@/constants/';

import {
  StrategyExprs,
  Formula as FormulaServer,
} from '@/features/api/chart/strategy/type';

export const addStrategyToFormulaExprs = (
  formulas: FormulaClient[],
  strategy: Array<string | LogicalOperator>,
): StrategyExprs => {
  return strategy.map((element) => {
    if (logicalOperators.some((operator) => operator === element)) {
      return element;
    }

    // formulas 배열 내의 요소의 candleKind 키를 kind로 변경
    const formula = formulas.find((formula) => formula.name === element);

    if (formula) {
      const { kind, name, tokens } = formula;

      const convertedTokens = tokens.map((token) => {
        if (typeof token === 'object') {
          const { indicator, shift, ...rest } = token as MarketIndicatorInfo;
          return {
            ...rest,
            name: indicator.name,
            shift: shift - 1,
          };
        } else {
          return token;
        }
      });

      return {
        name,
        tokens: convertedTokens,
        kind,
      } as FormulaServer;
    }
  }) as StrategyExprs;
};
