/**
 * 입력된 알파벳의 다음 알파벳 문자를 반환합니다.
 * 입력이 유효한 단일 알파벳 문자가 아닌 경우 'A'를 반환합니다.
 * 입력이 'A-Z' 후에는 'AA-AZ', 'BA-BZ'와 같은 식으로 연결됩니다.
 *
 * @param {string | undefined | null} alphabet - 단일 알파벳 문자.
 * @returns {string} 다음 알파벳 문자, 입력이 유효하지 않으면 'A'.
 */
export const getNextAlphabet = (
  alphabet: string | undefined | null,
): string => {
  if (!alphabet || !/^[A-Z]+$/.test(alphabet)) {
    return 'A';
  }

  const baseCharCode = 'A'.charCodeAt(0);
  const maxCharCode = 'Z'.charCodeAt(0);

  const chars = alphabet.split('').reverse();
  let carry = 1;

  const updatedChars = chars.map((char) => {
    if (carry === 0) {
      return char;
    }

    const currentCharCode = char.charCodeAt(0);
    const nextCharCode = currentCharCode + carry;

    if (nextCharCode <= maxCharCode) {
      carry = 0;
      return String.fromCharCode(nextCharCode);
    }

    carry = 1;
    return String.fromCharCode(baseCharCode); // Reset to 'A'.
  });

  if (carry > 0) {
    updatedChars.push(String.fromCharCode(baseCharCode));
  }

  return updatedChars.reverse().join('');
};
