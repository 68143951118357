import { logicalOperators } from '@/constants';

/**
 * 주어진 문자열이 숫자로만 구성되어 있는지 확인
 * @param {string} value - 확인할 문자열
 * @returns {boolean} 주어진 문자열이 숫자로만 구성되어 있으면 true, 그렇지 않으면 false를 반환
 */
export const validateNumber = (value: string): boolean => {
  const NUMBER_REGEX = /^\d*$/;
  return NUMBER_REGEX.test(value);
};

/**
 * 주어진 문자열이 숫자와 쉼표로만 구성되어 있는지 확인
 * @param {string} value - 확인할 문자열
 * @returns {boolean} 주어진 문자열이 숫자와 쉼표로만 구성되어 있으면 true, 그렇지 않으면 false를 반환
 */
export const validateCommaNumber = (value: string): boolean => {
  const COMMA_NUMBER_REGEX = /^[\d,]*$/;
  return COMMA_NUMBER_REGEX.test(value);
};

/**
 * 주어진 문자열 값이 0으로 시작하지 않는지 확인
 * @param {string} value - 확인할 문자열
 * @returns {boolean} 문자열 값이 0으로 시작하지 않으면 true, 그렇지 않으면 false를 반환
 */
export const checkNotStartingWithZero = (value: string): boolean => {
  return value[0] !== '0';
};

/**
 * 주어진 문자열이 연산자인지 확인
 * @param {string} kind - 확인할 문자열
 * @returns {boolean} 주어진 문자열이 연산자이면 true, 그렇지 않으면 false를 반환
 */
export const getIsOperator = (kind: string): boolean =>
  logicalOperators.some((operator) => operator === kind);
