import {
  formatCurrency,
  validateNumber,
  validateCommaNumber,
} from '@/features/utils';

// TODO: 들이는 시간 대비 의미가 없는 거 같아서 parser 파일의 유틸들은 리팩토링 보류
// TODO: 필요없는 함수들 삭제
export const parseCurrencyInput = (prevValue: string, value: string) => {
  if (value.length < 4) {
    return validateNumber(value) ? value : prevValue;
  } else {
    return validateCommaNumber(value) ? formatCurrency(value) : prevValue;
  }
};

export const parseNumberFormatString = (value: string) => {
  const removeComma = value.replaceAll(',', '');
  return Number(removeComma);
};
