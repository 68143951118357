import { NextRequest } from 'next/server';

import { routes } from '@/constants/';
import { env } from '@/features/env';

import { getToken } from 'next-auth/jwt';

export const noAuthPages = [
  routes.rootRoute,
  routes.homeRoute,
  routes.signInRoute,
  routes.signUpRoute,
  routes.privacyRoute,
  routes.termsRoute,
  routes.blogRoute,
  routes.ONBOARDING_FIRST_VISIT_ROUTE,
  '/callback/naver',
  '/callback/google',
  '/callback/coinone',
  '/callback/apple',
  '/callback/coinone',
  '/callback/credentials',
  '/_next/image',
  '/_next/image/',
  '/401',
  '/404',
];

export const checkJWT = async (request: NextRequest) => {
  const pathname = request.nextUrl.pathname;

  const pagesCondition =
    pathname.endsWith('/') && !pathname.includes('session');
  if (pagesCondition) {
    const token = await getToken({
      req: request,
      secret: env.NEXT_SECRET,
      raw: true,
    });

    if (!token && !noAuthPages.includes(pathname)) {
      const API_REGEX = /\/api\/auth\//;
      return API_REGEX.test(pathname);
    }
  }
  return true;
};

// export async function middleware(request: NextRequest) {
//   const isSignIn = await checkJWT(request);
//   const origin = `${request.nextUrl.origin}`;

//   // MEMO: 이 동작이 들어가게 되면 인증이 필요한 페이지 접근 자체를 차단하기 때문에 레퍼러를 불러올 수 없다.
//   if (!isSignIn) {
//     return NextResponse.redirect(`${origin}/signin`);
//   }

//   // const referrer = request.headers.get('referer') || '/';
//   // console.log('referrer: ', referrer);
//   return NextResponse.next();
// }

// MEMO: 미들웨어에서 페이지를 차단하면 클라이언트에서 브라우저 히스토리를 제어할 수 없다.
export async function middleware() {}
